import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Hidden from "@material-ui/core/Hidden"
import "./top.css"
import Button from "@material-ui/core/Button"
import { navigate } from "gatsby"
import WrapTypo from "../components/WrapTypo"
import { Box, Grid } from "@material-ui/core"

const Content1 = () => {
  const data = useStaticQuery(graphql`
    query {
      Furniture: file(relativePath: { eq: "furniture.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Wifi: file(relativePath: { eq: "wifi.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      UtilityCosts: file(relativePath: { eq: "utilityCosts.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Desk: file(relativePath: { eq: "desk.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Device: file(relativePath: { eq: "device.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Projector: file(relativePath: { eq: "projector.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Locker: file(relativePath: { eq: "locker.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Water: file(relativePath: { eq: "water.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Audio: file(relativePath: { eq: "audio.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Microwave: file(relativePath: { eq: "Microwave.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Coffee: file(relativePath: { eq: "coffee.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Refrigerator: file(relativePath: { eq: "refrigerator.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Store: file(relativePath: { eq: "store.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Beer: file(relativePath: { eq: "beer.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service: file(relativePath: { eq: "Service.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 225) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Box margin="0 0 0 4%">
        <Box width="100%" height="100%" maxWidth="735px" pt={4} pb={9}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Logo
              logo={data.Furniture.childImageSharp.fluid}
              title="家具"
              imageWidth="63.04px"
            />
            <Logo
              logo={data.Wifi.childImageSharp.fluid}
              title="高速Wi-Fi"
              imageWidth="63.17px"
            />
            <Logo
              logo={data.UtilityCosts.childImageSharp.fluid}
              title="水道光熱費"
              imageWidth="63.17px"
            />
            <Logo
              logo={data.Desk.childImageSharp.fluid}
              title="デスクスペース"
              imageWidth="63.17px"
            />
            <Logo
              logo={data.Device.childImageSharp.fluid}
              title="複合機"
              imageWidth="42.73px"
            />
            <Logo
              logo={data.Projector.childImageSharp.fluid}
              title="プロジェクター"
              imageWidth="63.17px"
            />
            <Logo
              logo={data.Locker.childImageSharp.fluid}
              title="ロッカー"
              imageWidth="63.17px"
            />

            <Logo
              logo={data.Refrigerator.childImageSharp.fluid}
              title="冷蔵庫"
              imageWidth="43.93px"
            />
            <Logo
              logo={data.Store.childImageSharp.fluid}
              title="無人コンビニ"
              imageWidth="40.01px"
            />
            <Logo
              logo={data.Beer.childImageSharp.fluid}
              title="ビール（有料）"
              imageWidth="44.47px"
            />
            <Logo
              logo={data.Water.childImageSharp.fluid}
              title="ウォーターサーバー"
              imageWidth="48.32px"
            />
            <Logo
              logo={data.Audio.childImageSharp.fluid}
              title="無線オーディオ"
              imageWidth="63.17px"
            />
            <Logo
              logo={data.Microwave.childImageSharp.fluid}
              title="電子レンジ"
              imageWidth="63.17px"
            />
            <Logo
              logo={data.Coffee.childImageSharp.fluid}
              title="淹れたてコーヒー"
              imageWidth="57.98px"
            />
          </Grid>
        </Box>
      </Box>
    </>
  )
}

const Logo = ({ logo, title, imageWidth }) => {
  return (
    <>
      <Hidden smUp>
        <Box height="90px" maxWidth="200px" width="calc(100%/3)" mt={5}>
          <Grid
            container
            direction="column"
            // justify="space-between"
            justify="space-between"
            alignItems="center"
            style={
              title == "ウォーターサーバー"
                ? { height: "100%", whiteSpace: "nowrap" }
                : { height: "100%" }
            }
          >
            <Grid item>
              <Box width={imageWidth}>
                <Img fluid={logo} />
              </Box>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Box textAlign="center">
                <WrapTypo color="#E8DED3" fs="12px" mt="0">
                  {title}
                </WrapTypo>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
      <Hidden xsDown>
        <Box height="90px" maxWidth="200px" width="calc(100%/4)" mt={5}>
          <Grid
            container
            direction="column"
            // justify="space-between"
            justify="space-between"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Box width={imageWidth}>
                <Img fluid={logo} />
              </Box>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Box textAlign="center">
                <WrapTypo color="#E8DED3" fs="12px" mt="0">
                  {title}
                </WrapTypo>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
    </>
  )
}

export default Content1
