import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import "../components/font.css"
import AOS from "aos"
import "aos/dist/aos.css"
import styled from "styled-components"
import { Box, Grid, Hidden, Button, Link } from "@material-ui/core"
import ServiceLogo from "../components/serviceLogo"
import ServiceTable from "../components/serviceTable"
import { ContactForm } from "../components/ContactForm"
import WrapTypo from "../components/WrapTypo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ChooseYourArt from "../components/ChoiceArt"
import { navigate } from "gatsby"
import { Alert } from "@material-ui/lab"
import { useForm } from "react-hook-form"
import firebase from "../Firebase"
import { Flag } from "@material-ui/icons"
import CookieConsent from "react-cookie-consent"

const isEmpty = obj => !Object.keys(obj).length

const emailRule = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "「メールアドレス」の入力形式が正しくありません。",
  },
}
const phoneNumberRule = {
  minLength: {
    value: 6,
    message: "「電話番号」の入力文字数が少なすぎます",
  },
  maxLength: {
    value: 12,
    message: "「電話番号」の入力文字数が多すぎます",
  },
  pattern: {
    value: /^[0-9]+$/,
    message: "「電話番号」の入力形式が正しくありません",
  },
}

const textRule = name => ({
  maxLength: {
    value: 50,
    message: `「${name}」の入力文字数が多すぎます`,
  },
})

const contextRule = {
  maxLength: {
    value: 1000,
    message: "「内容」入力文字数が多すぎます",
  },
}

const fetchProductData = async roomId => {
  const fetchProduct = await fetch(
    "https://yotsuya-office.microcms.io/api/v1/room/" + roomId,
    { headers: { "X-API-KEY": "5bc7ab07-9927-425b-a212-51abab8b1964" } }
  )
  try {
    return await fetchProduct.json()
  } catch (e) {}
}

const Home = ({ location }) => {
  const [imgUrl, setImgUrl] = useState("")
  const [clickedNum, setClicked] = useState(0)
  const data = useStaticQuery(graphql`
    query {
      twitter: file(relativePath: { eq: "twitterService.png" }) {
        childImageSharp {
          fluid(maxWidth: 733) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inst: file(relativePath: { eq: "instagramService.png" }) {
        childImageSharp {
          fluid(maxWidth: 733) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      face: file(relativePath: { eq: "facebookService.png" }) {
        childImageSharp {
          fluid(maxWidth: 733) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contact: file(relativePath: { eq: "contactBlack.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const parse = url => {
    const urlSplit = url.split("?")
    return urlSplit.length > 0
      ? [...new URLSearchParams(urlSplit[1]).entries()].reduce(
          (obj, e) => ({ ...obj, [e[0]]: e[1] }),
          {}
        )
      : ""
  }
  const [roomId, setRoomId] = useState(
    (location.state && location.state.roomId) ||
      (parse(typeof window !== `undefined` ? window.location.href : "") &&
        parse(typeof window !== `undefined` ? window.location.href : "").room)
  )

  const [artList, setArtList] = useState([])
  useEffect(() => {
    fetchProductData(roomId).then(data => {
      imageUrl(data.top.url, 0)
      setArtList(data)
    })
  }, [roomId])

  function imageUrl(imageUrl, num) {
    setImgUrl(imageUrl)
    setClicked(num)
  }

  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 800, // values from 0 to 3000, with step 50ms
      duration: 3000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false,
      anchorPlacement: "bottom-bottom",
    })
  }

  return (
    <>
      <Helmet />
      <CookieConsent
        location="bottom"
        buttonText="閉じる"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", fontFamily: "Noto Sans JP,sans-serif" }}
        buttonStyle={{
          color: "#fff",
          background: "#f00",
          fontSize: "13px",
          border: "none",
          fontFamily: "Noto Sans JP,sans-serif",
        }}
        expires={150}
      >
        <p style={{ fontFamily: "Noto Sans JP,sans-serif" }}>
          当ウェブサイトはCookieを使用しており、引き続き当サイトを閲覧される場合、当社の個人情報保護方針に同意したものといたします。
        </p>
        <a
          style={{
            textDecoration: "underline",
            fontFamily: "Noto Sans JP,sans-serif",
          }}
          href="https://www.sunfrt.co.jp/policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span
            style={{ fontSize: "10px", fontFamily: "Noto Sans JP,sans-serif" }}
          >
            個人情報保護方針
          </span>
        </a>
      </CookieConsent>
      <Layout>
        <Header />
        <Box>
          <Box position="relative">
            <Box className="black" bgcolor="#242424" paddingTop="90px">
              <Box margin="0 5%">
                <Box pb={6} width="100%" maxWidth="735px">
                  <Box mt={6}>
                    <WrapTypo
                      ff="Brooklyn"
                      fw="700"
                      fs="24px"
                      color="#E8DED3"
                      mt="0"
                    >
                      Room:{artList.name}
                    </WrapTypo>
                    <WrapTypo
                      ff="Brooklyn"
                      fw="700"
                      fs="24px"
                      color="#E8DED3"
                      mt="0"
                    >
                      Artist:{artList.artist}
                    </WrapTypo>
                  </Box>

                  <Hidden smUp>
                    <Box mt={3}>
                      <Box width="100%">
                        {artList.top && (
                          <img
                            src={artList.top.url}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Box>
                      <Box width="100%" mt={2}>
                        {artList.left && (
                          <img
                            src={artList.left.url}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Box>
                      <Box width="100%" mt={2}>
                        {artList.right && (
                          <img
                            src={artList.right.url}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Box>
                      <Box width="100%" mt={2}>
                        {artList.bottom && (
                          <img
                            src={artList.bottom.url}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Hidden>
                  <Hidden xsDown>
                    <Box maxWidth="735px" width="100%" mt={3}>
                      {artList.top && (
                        <img src={imgUrl} style={{ width: "100%" }} />
                      )}
                    </Box>
                    <Box maxWidth="735px" mt={3}>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                      >
                        <Grid itme style={{ maxWidth: "171px" }}>
                          <Box width="100%">
                            {() => {
                              if (artList.top && clickedNum == 0) {
                                return (
                                  <>
                                    <Button
                                      style={{ padding: "0" }}
                                      onClick={() => {
                                        imageUrl(artList.top.url, 0)
                                      }}
                                    >
                                      <img
                                        src={artList.top.url}
                                        style={{ width: "100%" }}
                                      />
                                    </Button>
                                  </>
                                )
                              } else if (artList.top && clickedNum !== 0) {
                                return (
                                  <>
                                    <Button
                                      style={{ padding: "0" }}
                                      onClick={() => {
                                        imageUrl(artList.top.url, 0)
                                      }}
                                    >
                                      <img
                                        src={artList.top.url}
                                        style={{
                                          width: "100%",
                                          WebkitFilter: "grayscale(100%)",
                                          filter: "grayscale(100%)",
                                        }}
                                      />
                                    </Button>
                                  </>
                                )
                              } else {
                                return <></>
                              }
                            }}
                          </Box>
                        </Grid>
                        <Grid itme style={{ maxWidth: "171px" }}>
                          <Box width="100%">
                            {() => {
                              if (artList.left && clickedNum == 1) {
                                return (
                                  <>
                                    <Button
                                      style={{ padding: "0" }}
                                      onClick={() => {
                                        imageUrl(artList.left.url, 1)
                                      }}
                                    >
                                      <img
                                        src={artList.left.url}
                                        style={{ width: "100%" }}
                                      />
                                    </Button>
                                  </>
                                )
                              } else if (artList.left && clickedNum !== 1) {
                                return (
                                  <>
                                    <Button
                                      style={{ padding: "0" }}
                                      onClick={() => {
                                        imageUrl(artList.left.url, 1)
                                      }}
                                    >
                                      <img
                                        src={artList.left.url}
                                        style={{
                                          width: "100%",
                                          WebkitFilter: "grayscale(100%)",
                                          filter: "grayscale(100%)",
                                        }}
                                      />
                                    </Button>
                                  </>
                                )
                              } else {
                                return <></>
                              }
                            }}
                          </Box>
                        </Grid>
                        <Grid itme style={{ maxWidth: "171px" }}>
                          <Box width="100%">
                            {() => {
                              if (artList.right && clickedNum == 2) {
                                return (
                                  <>
                                    <Button
                                      style={{ padding: "0" }}
                                      onClick={() => {
                                        imageUrl(artList.right.url, 2)
                                      }}
                                    >
                                      <img
                                        src={artList.right.url}
                                        style={{ width: "100%" }}
                                      />
                                    </Button>
                                  </>
                                )
                              } else if (artList.right && clickedNum !== 2) {
                                return (
                                  <>
                                    <Button
                                      style={{ padding: "0" }}
                                      onClick={() => {
                                        imageUrl(artList.right.url, 2)
                                      }}
                                    >
                                      <img
                                        src={artList.right.url}
                                        style={{
                                          width: "100%",
                                          WebkitFilter: "grayscale(100%)",
                                          filter: "grayscale(100%)",
                                        }}
                                      />
                                    </Button>
                                  </>
                                )
                              } else {
                                return <></>
                              }
                            }}
                          </Box>
                        </Grid>
                        <Grid itme style={{ maxWidth: "171px" }}>
                          <Box width="100%">
                            {() => {
                              if (artList.bottom && clickedNum == 3) {
                                return (
                                  <>
                                    <Button
                                      style={{ padding: "0" }}
                                      onClick={() => {
                                        imageUrl(artList.bottom.url, 3)
                                      }}
                                    >
                                      <img
                                        src={artList.bottom.url}
                                        style={{ width: "100%" }}
                                      />
                                    </Button>
                                  </>
                                )
                              } else if (artList.bottom && clickedNum !== 3) {
                                return (
                                  <>
                                    <Button
                                      style={{ padding: "0" }}
                                      onClick={() => {
                                        imageUrl(artList.bottom.url, 3)
                                      }}
                                    >
                                      <img
                                        src={artList.bottom.url}
                                        style={{
                                          width: "100%",
                                          WebkitFilter: "grayscale(100%)",
                                          filter: "grayscale(100%)",
                                        }}
                                      />
                                    </Button>
                                  </>
                                )
                              } else {
                                return <></>
                              }
                            }}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Hidden>

                  <Box>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <WrapTypo
                          ff="Brooklyn"
                          fw="700"
                          color="#E8DED3"
                          fs="18px"
                        >
                          Price
                        </WrapTypo>
                        {artList.price ? (
                          artList.price.indexOf("WEEK") == 0 ? (
                            <a href="https://week-office.com/">
                              <WrapTypo
                                ff="Brooklyn"
                                fw="700"
                                color="#E8DED3"
                                fs="18px"
                                style={{ textDecoration: "underline" }}
                              >
                                {artList.price} | WEEK web site
                              </WrapTypo>
                            </a>
                          ) : (
                            <WrapTypo
                              ff="Brooklyn"
                              fw="700"
                              color="#E8DED3"
                              fs="16px"
                            >
                              ￥{artList.price}~/月
                            </WrapTypo>
                          )
                        ) : (
                          <WrapTypo
                            ff="Brooklyn"
                            fw="700"
                            color="#E8DED3"
                            fs="14px"
                          >
                            ￥{artList.price}~/月
                          </WrapTypo>
                        )}
                      </Grid>
                      <Grid item>
                        <WrapTypo
                          ff="Brooklyn"
                          fw="700"
                          color="#E8DED3"
                          fs="16px"
                        >
                          No. of Desks
                        </WrapTypo>
                        <WrapTypo
                          ff="Brooklyn"
                          fw="700"
                          color="#E8DED3"
                          fs="16px"
                        >
                          {artList.deskNum}
                        </WrapTypo>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box textAlign="Justify">
                    <WrapTypo ff="Brooklyn" fw="700" color="#E8DED3" fs="16px">
                      Concept
                    </WrapTypo>
                    <WrapTypo ff="Brooklyn" fw="700" color="#E8DED3" fs="14px">
                      {artList.concept}
                    </WrapTypo>
                  </Box>

                  <Box textAlign="Justify">
                    <WrapTypo ff="Brooklyn" fw="700" color="#E8DED3" fs="16px">
                      Profile
                    </WrapTypo>
                    <WrapTypo ff="Brooklyn" fw="700" color="#E8DED3" fs="14px">
                      {artList.profile}
                    </WrapTypo>
                  </Box>

                  <Box maxWidth="190px" mt={6}>
                    <Grid container>
                      {artList.twitter && (
                        <Grid item>
                          <Box width="40.6px" mr={2}>
                            <a
                              href={artList.twitter}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Img fluid={data.twitter.childImageSharp.fluid} />
                            </a>
                          </Box>
                        </Grid>
                      )}
                      {artList.facebook && (
                        <Grid item>
                          <Box width="19.15px" mr={2}>
                            <a
                              href={artList.facebook}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Img fluid={data.face.childImageSharp.fluid} />
                            </a>
                          </Box>
                        </Grid>
                      )}
                      {artList.instagram && (
                        <Grid item>
                          <Box width="35.07px" mr={2}>
                            <a
                              href={artList.instagram}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Img fluid={data.inst.childImageSharp.fluid} />
                            </a>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box bgcolor="#E8DED3">
              <ServiceTable />
            </Box>
            <Box bgcolor="#000">
              <ServiceLogo />
            </Box>
            <Hidden lgUp>
              <Box bgcolor="#E8DED3">
                <Box margin="0 5% 0 5%">
                  <Box width="100%" height="100%" maxWidth="225px" py={4}>
                    <Img fluid={data.contact.childImageSharp.fluid} />
                  </Box>

                  <Box pb={6} width="100%" maxWidth="735px">
                    <ContactForm selectRoomId={roomId} />
                  </Box>
                </Box>
              </Box>
            </Hidden>

            <Hidden mdDown>
              <Box position="absolute" top="0" right="5%" height="100%">
                <Box margin="144px 0 0" position="sticky" top="0">
                  <Box padding="90px 0 0">
                    <Box bgcolor="#fff" padding="20px">
                      <Grid container>
                        <Grid item xs={12}>
                          <Box maxWidth="126px" margin="auto">
                            <Img fluid={data.contact.childImageSharp.fluid} />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box mt={2} minWidth="400px">
                        <ContactFormaa selectRoomId={roomId} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Hidden>
          </Box>
        </Box>
        <Footer />
      </Layout>
    </>
  )
}

const ContactFormaa = ({ selectRoomId, minFlag }) => {
  const { handleSubmit, register, errors } = useForm({
    defaultValues: { requestRoom: selectRoomId },
  })
  const [loading, setLoading] = React.useState(false)
  const [privacyAgreement, setPrivacyAgreement] = React.useState(false)
  const onSubmit = values => {
    setLoading(true)
    const docId = firebase.firestore().collection("contacts").doc().id
    firebase
      .firestore()
      .collection("contacts")
      .doc(docId)
      .set({
        ...values,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        navigate("/contacts-result/", { state: { result: "success" } })
        setLoading(false)
      })
      .catch(e => {
        navigate("/contacts-result/", { state: { result: "faild" } })
        setLoading(false)
      })
  }

  const errKeys = Object.keys(errors)
  return (
    <>
      <Box maxWidth="400px">
        <Grid container>
          <Grid item xs={12}>
            <>
              {!isEmpty(errors) && errKeys.length > 0 && (
                <Box mb={2}>
                  <Alert severity="error" style={{ color: "#ff0000" }}>
                    {errKeys.map(k => (
                      <React.Fragment key={k}>
                        <WrapTypo color="#ff0000">
                          ・{errors[k].message}
                        </WrapTypo>
                      </React.Fragment>
                    ))}
                  </Alert>
                </Box>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <Box style={{ textAlign: "left" }}>
                  <InputFormItem
                    title="会社名"
                    placeholder=""
                    name="company"
                    register={register}
                    rule={textRule("会社名")}
                    required={false}
                  />
                  <InputFormItem
                    title="お名前*"
                    placeholder="  山田太郎"
                    name="name"
                    register={register}
                    rule={textRule("お名前")}
                    required={true}
                  />
                  <InputFormItem
                    title="電話番号"
                    placeholder="  0312345678"
                    name="phoneNumber"
                    register={register}
                    rule={phoneNumberRule}
                  />
                  <InputFormItem
                    name="emailAdress"
                    title="メールアドレス*"
                    placeholder="  email@example"
                    register={register}
                    rule={emailRule}
                    required={true}
                  />
                  <SelectFormItem
                    roomId=""
                    name="employeeRange"
                    title="従業員数"
                    optionsText={[
                      "-",
                      "1人",
                      "2〜5人",
                      "6~10人",
                      "11〜15人",
                      "16人以上",
                    ]}
                    register={register}
                    required={false}
                  />
                  <InputFormItem
                    name="budget"
                    title="月のご予算"
                    placeholder="  50万円"
                    register={register}
                    rule={textRule("月のご予算")}
                    required={false}
                  />
                  <SelectFormItem
                    name="requestRoom"
                    title="ご希望の部屋"
                    optionsText={[
                      "-",
                      "101/1NW1 (Artist:Frankie Cihi)",
                      "102/1NW2 (Artist:KATHMI)",
                      "103/3N1（Artist:Jay Shogo）",
                      "104/4NE1 (Artist:丸橋 聡)",
                      "201/1NW2 (Artist:Maharo)",
                      "202/2NW2 (Artist:Tadaomi Shibuya)",
                      "203/3NW2 (Artist:Jay Shogo)",
                      "204/4N2 (Artist:高橋 洋平)",
                      "205/5NE2 (Artist:Jay Shogo)",
                      "206/6NE2 (Artist:高橋 洋平)",
                      "207/7SE2 (Artist:COIN PAEKING DELIVERRY)",
                      "301/1NW3 (Artist:classicdraw/クラシックドロー)",
                      "302/2NW3 (Artist:OLI)",
                      "303/3NW3 (Artist:Frankie Cihi)",
                      "304/4N3 (Artist:Frankie Cihi)",
                      "305/5NE3 (Artist:COIN PARAKING DELIVERY)",
                      "306/6NE3 (Artist:COIN PARKING DELIVERY)",
                      "307/7SE3 (Artist:COIN PARKING DELIVERY)",
                      "401/1NW4 (Artist:丸橋 聡)",
                      "402/2NW4 (Artist:OLI)",
                      "403/3NE4 (Artist:KATHMI)",
                      "404/4SE4 (Artist:Frankie Cihi)",
                    ]}
                    register={register}
                    rule={textRule("ご希望の部屋")}
                    required={false}
                  />
                  <SelectFormItem
                    roomId=""
                    name="business"
                    title="ご用件*"
                    optionsText={[
                      "-",
                      "お部屋のお申し込み",
                      "内覧のご予約",
                      "お問い合わせ",
                      "イベントスペースのご予約",
                    ]}
                    register={register}
                    required={true}
                  />
                  <TextAreaFormItem
                    name="context"
                    title="内容"
                    placeholder="お問い合わせ内容を記載してください。　　　　　例）施設を見学したい。利用体験をしてみたい。"
                    register={register}
                    rule={contextRule}
                  />
                </Box>
                <Box width={"100%"} my={4}>
                  <WrapTypo
                    color="#232323"
                    mt={"24px"}
                    fw={"500"}
                    fs={"16px"}
                    lh={"180%"}
                    letterSpacing={"2%"}
                    mb={"24px"}
                  >
                    ※お問い合わせいただく際は、必ず
                    <a
                      style={{
                        color: "#2222ff",
                        textDecoration: "underline",
                      }}
                      href="https://www.sunfrt.co.jp/policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      個人情報保護方針
                    </a>
                    をご確認いただき、同意のうえお問い合わせください。ご同意いただくには、以下の項目をチェックしてください。
                  </WrapTypo>
                  <Box
                    display="flex"
                    width={"100%"}
                    maxWidth={"600px"}
                    m={"auto"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <input
                      type="checkbox"
                      id="horns"
                      name="horns"
                      value={privacyAgreement}
                      onChange={e => setPrivacyAgreement(c => !c)}
                    ></input>
                    <WrapTypo
                      color="#232323"
                      mt={"24px"}
                      fw={"500"}
                      fs={"16px"}
                      lh={"21.6px"}
                      letterSpacing={"2%"}
                      mb={"24px"}
                      mr={"45px"}
                      ml={"45px"}
                    >
                      個人情報保護方針に同意する
                    </WrapTypo>
                  </Box>
                </Box>
                <Box textAlign="center">
                  {!loading ? (
                    <Button
                      disabled={!privacyAgreement}
                      variant="outlined"
                      type="submit"
                      style={{
                        borderRadius: "0px",
                        border: "2px solid #1A1A1A",
                        backgroundColor: privacyAgreement ? "#232323" : "#777",
                      }}
                    >
                      <WrapTypo
                        color="#fff"
                        fw={"700"}
                        fs={"18px"}
                        lh={"21.6px"}
                        letterSpacing={"2%"}
                        mt="8px"
                        mb="8px"
                      >
                        {privacyAgreement
                          ? "この内容で送信"
                          : "個人情報保護方針に同意して下さい"}
                      </WrapTypo>
                    </Button>
                  ) : (
                    <Button disabled={true} variant="outlined" type="submit">
                      <WrapTypo mt={"8px"} mb={"8px"}>
                        送信中
                      </WrapTypo>
                    </Button>
                  )}
                </Box>
                <Box textAlign="center">
                  <WrapTypo fs="12px">＊記入必須項目</WrapTypo>
                </Box>
              </form>
            </>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const InputFormItem = ({
  title,
  placeholder,
  name,
  register,
  rule,
  required,
}) => {
  const requiredObj = required
    ? { required: `「${title.replace("*", "")}」の項目が未入力です` }
    : {}
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={3}>
          <WrapTypo fs="12px" lh="24.96px" ls="16%" fw="500" mt="0">
            {title}
          </WrapTypo>
        </Grid>
        <Grid item xs={12} sm={9}>
          <input
            type="text"
            name={name}
            ref={register({
              ...requiredObj,
              ...rule,
            })}
            placeholder={placeholder}
            style={{
              width: "295px",
              height: "27px",
              border: "solid 2.5px rgba(207, 207, 207, 1)",
              color: "#1A1A1A",
              fontFamily: "Roboto,Noto Sans JP, sans-serif",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "25px",
              letterSpacing: "0em",
              textAlign: "left",
              backgroundColor: "#fff",
            }}
          />
        </Grid>
      </Grid>
      <Box mb={2} />
    </>
  )
}

const TextAreaFormItem = ({ title, placeholder, name, register, rule }) => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <WrapTypo fs="12px" lh="24.96px" ls="16%" fw="500">
            {title}
          </WrapTypo>
        </Grid>
        <Grid item xs={12} sm={9}>
          <textarea
            name={name}
            ref={register({
              required: false,
              ...rule,
            })}
            placeholder={placeholder}
            style={{
              height: "100px",
              fontSize: "12px",
              width: "295px",
              color: "#1A1A1A",
              border: "solid 2.5px rgba(207, 207, 207, 1)",
              fontFamily: "Roboto,Noto Sans JP, sans-serif",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "25px",
              letterSpacing: "0em",
              textAlign: "left",
              paddingTop: "8px",
              paddingLeft: "5px",
            }}
          />
        </Grid>
      </Grid>
      <Box mb={2} />
    </>
  )
}

const SelectFormItem = ({ title, optionsText, register, name, required }) => {
  const requiredObj = required
    ? { required: `「${title.replace("*", "")}」の項目が未入力です` }
    : {}
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={3}>
          <WrapTypo fs="12px" lh="24.96px" ls="16%" fw="500" mt="0">
            {title}
          </WrapTypo>
        </Grid>
        <Grid item xs={12} sm={9}>
          <select
            name={name}
            ref={register({
              ...requiredObj,
            })}
            style={{
              width: "300px",
              height: "32px",
              border: "solid 2.5px rgba(207, 207, 207, 1)",
              color: "#1A1A1A",
              fontFamily: "Roboto,Noto Sans JP, sans-serif",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "25px",
              letterSpacing: "0em",
              textAlign: "left",
              backgroundColor: "#fff",
              maxWidth: "300px",
            }}
          >
            {optionsText.map(x => (
              <option style={{ color: "#1A1A1A" }}>{x}</option>
            ))}
          </select>
        </Grid>
      </Grid>
      <Box mb={2} />
    </>
  )
}

export default Home
