import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import "./top.css"
import { navigate } from "gatsby"
import { Box, Grid, Hidden, Button } from "@material-ui/core"
import WrapTypo from "../components/WrapTypo"

const tableArray = [
  { title: "サービス項目", content: "サービス詳細" },
  { title: "利用時間", content: "9:00~22:00（24時間365日　入退室可能）" },
  {
    title: "コミュニティ ーマネージャー",
    content: "9:00~21:00（土日・祝日・夏季休暇・年末年始を除く）",
  },
  {
    title: "郵便物・宅配物",
    content: "専用郵便ポスト（宅配物は自己管理・対応をお願い致します）",
  },
  {
    title: "ドリンクサービス",
    content: "Brooklyn Roasting Company　コーヒー（1日2~3回抽出）",
    content1: "※ゲストは有料（¥150-/cup）となります。",
    content2: "Brooklyn Brewery ビール（有料）・ウォーターサーバー",
  },
  {
    title: "会議室",
    content: "共用会議室・ラウンジ・ルーフトップ・イベントスペース利用可",
  },
  {
    title: "その他設備",
    content: "複合機・Wi-Fi・冷蔵庫・電子レンジ・物販コーナー（有料）",
  },
  { title: "", content: "" },
  { title: "貸室", content: "" },
  {
    title: "ルームクリーニング・廃棄物回収",
    content: "貸室内清掃（週1~2回）・ゴミは共用ゴミ捨てにを利用ください",
    content1: "（大型の廃棄物については、有料となります）",
  },
  {
    title: "水道・電気",
    content: "水道及び電気使用料は全て施設利用料に含みます",
  },
  {
    title: "個別空調",
    content: "貸室毎の温度調節可（冷暖フリーではありません）",
  },
  { title: "Wi-Fi", content: "各貸室毎アクセスキー" },
  { title: "", content: "" },
  { title: "その他", content: "" },
  { title: "法人登記", content: "可" },
  { title: "同居", content: "可（事前の同居申請及び審査が必要です）" },
  {
    title: "鍵",
    content: "カード式入館管理システム・施錠エントランスはApp解錠可",
  },
  { title: "受付システム", content: "なし" },
]

const Content1 = () => {
  const data = useStaticQuery(graphql`
    query {
      service: file(relativePath: { eq: "Service_Table.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Box width="94%" m={"auto"}>
        <Box>
          <Box width="100%" maxWidth="400px" py={4}>
            <Img fluid={data.service.childImageSharp.fluid} />
          </Box>

          <Box pb={6} width="100%" maxWidth="800px">
            <Hidden xsDown>
              <Box ml={3}>
                <ServiceTable array={tableArray} />
              </Box>
            </Hidden>
            <Hidden smUp>
              <MobileServiceTable array={tableArray} />
            </Hidden>
          </Box>
        </Box>
      </Box>
    </>
  )
}

const ServiceTable = ({ array }) => {
  const items = []
  for (let i = 0; i < array.length; i++) {
    if (i == 0 || i == 8 || i == 14) {
      items.push(
        <>
          <tr>
            <td
              style={{
                border: "solid 1px #1A1A1A",
                borderCollapse: "collapse",
              }}
            >
              <Box padding="7px 0 7px 20px">
                <WrapTypo fs="14px" fw="700" mt="0" color="#1A1A1A">
                  {array[i].title ? array[i].title : <br />}
                </WrapTypo>
              </Box>
            </td>
            <td
              style={{
                border: "solid 1px #1A1A1A",
                borderCollapse: "collapse",
              }}
            >
              <Box padding="7px 0 7px 20px">
                <WrapTypo fs="14px" fw="700" mt="0" color="#1A1A1A">
                  {array[i].content ? array[i].content : <br />}
                  {array[i].content1 && <br />}
                  {array[i].content1 && array[i].content1}
                  {array[i].content2 && <br />}
                  {array[i].content2 && array[i].content2}
                </WrapTypo>
              </Box>
            </td>
          </tr>
        </>
      )
    } else {
      items.push(
        <>
          <tr>
            <td
              style={{
                border: "solid 1px #1A1A1A",
                borderCollapse: "collapse",
              }}
            >
              <Box padding="7px 0 7px 20px">
                <WrapTypo fs="14px" mt="0" color="#1A1A1A">
                  {array[i].title ? array[i].title : <br />}
                </WrapTypo>
              </Box>
            </td>
            <td
              style={{
                border: "solid 1px #1A1A1A",
                borderCollapse: "collapse",
              }}
            >
              <Box padding="7px 0 7px 20px">
                <WrapTypo fs="14px" mt="0" color="#1A1A1A">
                  {array[i].content ? array[i].content : <br />}
                  {array[i].content1 && <br />}
                  {array[i].content1 && array[i].content1}
                  {array[i].content2 && <br />}
                  {array[i].content2 && array[i].content2}
                </WrapTypo>
              </Box>
            </td>
          </tr>
        </>
      )
    }
  }

  return (
    <>
      {/* <Grid container> */}
      <Box>
        <table
          style={{
            border: "solid 2px #1A1A1A",
            borderCollapse: "collapse",
            width: "95%",
          }}
        >
          {items}
        </table>
      </Box>
      {/* </Grid> */}
    </>
  )
}

const MobileServiceTable = ({ array }) => {
  const items = []

  for (let i = 1; i < array.length; i++) {
    if (array[i].title && array[i].content) {
      items.push(
        <>
          <Box mt={4} textAlign="justify">
            <WrapTypo fs="16px" fw="700" mt="0" color="#1A1A1A">
              【{array[i].title ? array[i].title : <br />}】
            </WrapTypo>
          </Box>
          <Box mt={2} ml={1} textAlign="justify">
            <WrapTypo fw="700" lh="180%" fs="14px" mt="0" color="#1A1A1A">
              {array[i].content ? array[i].content : <br />}
              {array[i].content1 && <br />}
              {array[i].content1 && array[i].content1}
              {array[i].content2 && <br />}
              {array[i].content2 && array[i].content2}
            </WrapTypo>
          </Box>
        </>
      )
    } else if (array[i].title && !array[i].content) {
      items.push(
        <>
          <Box mt={8} ml={1} textAlign="Justify">
            <WrapTypo fs="24px" fw="700" color="#1A1A1A">
              {array[i].title ? array[i].title : <br />}
            </WrapTypo>
          </Box>
        </>
      )
    } else {
    }
  }
  return <>{items}</>
}

export default Content1
